const titleHtml =`
  <div class="cof-row cof-title">
    <div class="cof-image"></div>
    <div class="cof-name">
      <h4>EVENT</h4>
    </div>
    <div class="cof-date">
      <h4>DATE</h4>
    </div>
    <div class="cof-location">
      <h4>LOCATION</h4>
    </div>
  </div>`

$(document)
  .on('turbo:load', function() {
    if ($('.cof-title').length === 0) {
      $('.event-list').prepend(titleHtml)
      $('.sub-title').after(titleHtml)
    }
    $('.event-item:visible').filter(':even').addClass('even-results-row')
  })
