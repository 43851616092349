$(document)
  .on('click', '.video-container .video-text-row', function() {
    if ($(this).hasClass('active-icon-row')) {
      $('.video-container .video-text-row')
        .removeClass('shrunk-icon-row active-icon-row')
    } else {
      $('.video-container .video-text-row')
        .addClass('shrunk-icon-row')
        .removeClass('active-icon-row')
      $(this).addClass('active-icon-row')
    }
  })
  .on('click', function(e) {
    if ($('.video-container .video-text')[0] &&
      (!$(e.target).closest('.video-container .video-text-row').length >= 1)) {
      $('.video-container .video-text-row').
        removeClass('shrunk-icon-row active-icon-row')
    }
  })
